/*anchor*/
a {
    color: #3e4676 !important;
}

a:hover {
    color: #419cd2 !important;
}
a:active{
    color: #419cd2 !important;
}
.nav-link{
    padding: 0px;
}
/*panel*/
.card {
    border: none;
    box-shadow: none;
    margin-bottom: 20px;
    background-color: #fcfcfc;
}

.card-header {
    border-color:#eff2f7 ;
    background: #fcfcfc;
    font-size: 16px;
    font-weight: 300;
}

.main-footer {
  border-color:#eff2f7 ;
  background: #fcfcfc;
  padding-top: 3em;
  position: relative;
  width: 100%;
}


.card-title {
    color: #3e4676;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
}

.card .badge{
    font-size: 12px;
}

/*label*/

.label {
    padding: 0.5em 0.8em;
}

.label-default {
    background-color: #1e293d;
}

.label-primary {
    background-color: #1f3869;
}

.label-success {
    background-color: #80d6a3;
}

.label-info {
    background-color: #87a4d8;
}

.label-warning {
    background-color: #edc569;
}

.label-danger {
    background-color: #ec5493;
}

.label-inverse {
    background-color: #344860;
}

/*text color*/

.text-danger {
    color: #ec5493;
}

.text-muted {
    color: #a1a1a1;
}

.text-primary {
    color: #1f3869;
}

.text-warning {
    color: #edc569;
}

.text-success {
    color: #80d6a3;
}

.text-info {
    color: #87a4d8;
}

.form-control {
    color: #3e4676 !important;
    font-size: .8rem;
    height: calc(1.5em + .75rem + 7px);
}


/*modal*/

.modal-content {
    box-shadow: none;
    border: none;
}

.modal-header {
    background: #1f3869;
    color: #fff;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    border-bottom: none;
}

/*text input*/

.form-control {
    box-shadow: none;
    color: #3e4676;
    border: 1px solid #dee6f2;
}


.form-control:focus, #focusedInput {
    border: 1px solid rgb(125, 154, 188);
    box-shadow: none;
}

.form-horizontal .control-label {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
}

input, textarea, select, button {
    outline: none !important;
}

.input-group-prepend .input-group-text {
    color: #3e4676 !important;
    border: 1px solid #dee6f2 !important;
    background-color: #dee6f2 !important;
    width: 91px !important;
}
.input-group input{
    border: 1px solid #dee6f2 !important;
    padding: .375rem .75rem !important;
    border-radius: .25rem !important;
    flex: 1 !important;

}
.input-group > .input-group-prepend > .input-group-text{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}



/*list*/

ul {
    padding-left: 0;
}

/*button*/

.btn-default {
    background-color: #1e293d;
    border-color: #1e293d;
    color: #fff;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #000000;
    border-color: #000000;
    color: #fff;
}

.btn-default:disabled:hover, .btn-default:disabled:focus, .btn-default:disabled:active {
    color: #333;
}

.btn-primary ,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary.disabled.active,
.btn-primary.active[disabled]{
    background-color: #1f3869;
    border-color: #1f3869;
    color: #FFFFFF;
}
.btn-signature{
margin: 0 0 10px 0;
font-size: 25px;
font-weight: 525;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary.disabled.active,
.btn-primary.active[disabled]{
    background-color: #2a598c;
    border-color: #2a598c;
    color: #FFFFFF;
}


.btn-success {
    background-color: #80d6a3;
    border-color: #80d6a3;
    color: #FFFFFF;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
    background-color: #59ca87;
    border-color: #59ca87;
    color: #FFFFFF;
}

.btn-info {
    background-color: #87a4d8;
    border-color: #87a4d8;
    color: #FFFFFF;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
    background-color: #6187cb;
    border-color: #6187cb;
    color: #FFFFFF;
}

.btn-warning {
    background-color: #edc569;
    border-color: #edc569;
    color: #FFFFFF;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
    background-color: #e8b33b;
    border-color: #e8b33b;
    color: #FFFFFF;
}

.btn-danger {
    background-color: #ec5493;
    border-color: #ec5493;
    color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-color: #e72676;
    border-color: #e72676;
    color: #FFFFFF;
}


.btn-white {
    box-shadow: none !important;
}

.btn-link{
   color:#419cd2;
}
.btn-link:hover{
    color:#1f3869
}
/*Rounded Button*/

.btn-round {
    border-radius: 30px;
    -webkit-border-radius: 30px;
}

/*shadow button*/

.btn-shadow.btn-default {
    box-shadow: 0 4px #9c9c9c;
}
.btn-shadow.btn-primary {
    box-shadow: 0 4px #29b392;
}
.btn-shadow.btn-success {
    box-shadow: 0 4px #61a642;
}
.btn-shadow.btn-info {
    box-shadow: 0 4px #1caadc;
}
.btn-shadow.btn-warning {
    box-shadow: 0 4px #cab03f;
}
.btn-shadow.btn-danger {
    box-shadow: 0 4px #d1595a;
}


/*dropdown shadow*/

.btn-group.open .dropdown-toggle, .btn-white.active, .btn:active, .btn.active {
    box-shadow: none;
}

/*dropdown select bg*/
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #495d74;
    color: #419cd2 !important;
    text-decoration: none;
}

/*split dropdown btn*/

.btn-white {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border-color: rgba(150, 160, 180, 0.3);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05) inset;
}

/*breadcrumbs*/

.breadcrumb {
    background-color: #fff;
}


/*tab*/

.nav-tabs > li > a {
    margin-right: 1px;
}

/*collapse*/

.panel-default > .panel-heading {
    background-color: #FFFFFF;
    border-color: #DDDDDD;
    color: #797979;
}

/*nav inverse*/

.navbar-inverse {
    background-color: #7087A3;
    border-color: #7087A3;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus{
    background-color: #61748d;
}

.navbar-inverse .navbar-nav > li a:hover {
    color: #2A3542;
}

.navbar-inverse .navbar-nav > li > ul > li a:hover {
    color: #fff;
}

.navbar-inverse .navbar-brand {
    color: #FFFFFF;
}

.navbar-inverse .navbar-nav > li > a {
    color: #fff;
}

.navbar-inverse .navbar-nav > .dropdown > a .caret {
    border-bottom-color: #fff;
    border-top-color: #fff;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #000;
}
.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
}

/*nav justified*/

.nav-justified {
    width: auto !important;
}

.nav-justified li:last-child > a:hover, .nav-justified li.active:last-child > a {
    border-radius: 0 4px 0 0 !important;
    -webkit-border-radius: 0 4px 0 0 !important;
}

/*list group*/


.list-group-item {
    border: 1px solid rgb(239, 242, 247);
    background-color: #fcfcfc;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #00A8B3;
    border-color: #00A8B3;
    color: #FFFFFF;
    z-index: 2;
}

.list-group-item-heading {
    font-weight: 300;
}

/*progress*/

.progress {
    box-shadow: none;
    background: #f0f2f7;
    margin-bottom: 1rem;
}

/*alert*/

.alert-success, .alert-danger, .alert-info, .alert-warning {
    border: none;
}

/*table*/

.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
    padding: 10px;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    margin-left: 0;
    position: static;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(240, 241, 243);
}

/*accordion*/

.accordion .card {
    margin-bottom: .5rem;
}


/**/

.wysihtml5-sandbox {
    border-color: rgb(194, 199, 203);
}

.btn-outline-secondary {
    border-color: #ced4da;
}

/**/

.row-fluid {
    width: 100%;
}

/*signature canvas*/
.dropzone-wrapper {
    position: relative;
}
.dropzone {
    /*position: relative;*/
    border: 1px solid rgba(0,0,0,0.08);
    background: rgba(0,0,0,0.02);
    border-radius: 3px;
}
.dropzone-wrapper .default-message{
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    /*background-color: #00a9b4;*/
    font-size: large;
    font-weight: bolder;
}
.hide-msg{
    opacity: 0 !important;
    /*background-color: red;*/
}

/*Table*/
.table{
    color:#3e4676;
}

/*-------------------------------------------------------------------------------*/
/*------------Reset swal2 colors--------------------------------------------------*/
/*-------------------------------------------------------------------------------*/
.swal2-confirm{
    background-color: #1f3869 !important;
}
.swal2-confirm:hover{
    background-color: #2a598c !important;
}

.swal2-success{
    background-color: #80d6a3 !important;
}
.swal2-success:hover{
    background-color: #59ca87 !important;
}

.swal2-danger{
    background-color: #ec5493 !important;
}
.swal2-danger:hover{
    background-color: #e72676 !important;
}
/*-------------------------------------------------------------------------------*/
/*------------Badges colors------------------------------------------------------*/
/*-------------------------------------------------------------------------------*/
.badge-danger {
    background-color: #ec5493 !important;
}

.badge-muted {
    background-color: #a1a1a1 !important;
}

.badge-primary {
    background-color: #1f3869 !important;
}

.badge-warning {
    background-color: #edc569 !important;
    color: white;
}

.badge-success {
    background-color: #80d6a3 !important;
}

.badge-info {
    background-color: #87a4d8 !important;
}
